<template lang="pug">
  v-container(fill-height)
    v-row(align="center" justify="center")
      v-col.form-col(v-show="!isFormSent")
        div.mb-8.beyond-title {{ title }}
        v-form#contact-form(ref="form" v-model="valid" action="/formSubmitted" @submit.prevent="sendForm")
          v-row(justify="space-between" no-gutters)
            v-col(v-for="(input, i) of inputs" :key="i" cols="12")
              v-text-field.form-input.rounded-lg(v-if="input.type === 'text'" v-model="formData[input.model]" :label="getInputLabel(input)" :rules="input.rules" :dense="$vuetify.breakpoint.lgAndDown" autocomplete="off" outlined)
              v-textarea.form-input.rounded-lg(v-if="input.type === 'textarea'" v-model="formData[input.model]" :label="getInputLabel(input)" :rules="input.rules" :dense="$vuetify.breakpoint.lgAndDown" rows="8" autocomplete="off" outlined auto-grow)
          v-row(justify="center" no-gutters)
            v-col()
              v-btn.white--text(:disabled="!valid" :loading="sendingForm" type="submit" color="formSendBtn" depressed large rounded block) {{ sendBtnText }}
      v-col.form-col(v-show="isFormSent")
        div.mb-8.beyond-title Sucesso!
        v-row(justify="center")
          v-col()
            v-btn.white--text(color="formSendBtn" outlined large rounded block to="/") Retornar à página inicial
          v-col()
            v-btn.white--text(color="formSendBtn" outlined large rounded block @click="resetForm") Novo formulário
</template>

<script>
export default {
  computed: {
    inDialog() {
      return this.$vuetify.breakpoint.mdAndUp
    }
  },
  data() {
    return {
      valid: true,
      sendingForm: false,
      isFormSent: false,
      sendBtnText: "Enviar",
      title: "Entre em contato",
      inputs: [
        {
          required: true,
          label: "Nome",
          type: "text",
          model: "name",
          rules: [(v) => !!v || "O nome é obrigatório"]
        },
        {
          required: true,
          label: "E-mail",
          type: "text",
          model: "email",
          rules: [
            (v) => !!v || "O e-mail é obrigatório",
            (v) => /.+@.+\..+/.test(v) || "O e-mail deve ser válido"
          ]
        },
        {
          required: false,
          label: "Telefone",
          type: "text",
          model: "numTel",
          rules: []
        },
        {
          required: true,
          label: "Assunto",
          type: "text",
          model: "subject",
          rules: [(v) => !!v || "O assunto é obrigatório"]
        },
        {
          required: true,
          label: "Mensagem",
          type: "textarea",
          model: "mensagem",
          rules: [(v) => !!v || "Digite a mensagem"]
        }
      ],
      formData: {
        name: "",
        email: "",
        subject: "",
        numTel: "",
        mensagem: ""
      }
    }
  },
  methods: {
    getInputLabel(input) {
      return `${input.label}${input.required ? "*" : ""}`
    },
    async sendForm() {
      this.sendingForm = true

      const props = []
      const values = []
      for (const input of this.inputs) {
        props.push(input.label)
        values.push(this.formData[input.model])
      }

      await this.$store.dispatch("page/sendForm", {
        to: ["contato@beyondcompany.com.br"],
        template: {
          name: "contact",
          data: {
            subject: `${this.formData.subject} - Contato | Beyond Company`,
            from: "Contato | Beyond Company",
            props,
            values
          }
        }
      })

      this.sendingForm = false
      this.isFormSent = true
    },
    resetForm() {
      this.$refs.form.reset()
      this.isFormSent = false
    }
  }
}
</script>

<style lang="sass" scoped>
.form-col
  margin: 0 10px

  @media(min-width: 600px)
    margin: 0 10vw

  @media(min-width: 960px)
    margin: 0 5vw

  @media(min-width: 1264px)
    margin: 0 10vw

.beyond-title
  font-size: 36px

.v-btn
  font-weight: 400
  height: 47px !important
  font-size: 17px
  letter-spacing: 0.21em
</style>
