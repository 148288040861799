<template lang="pug">
  v-container(fill-height)
    v-row(align="center" style="height: 100%;")
      v-col.banner-col
        v-img(src="../../assets/contact/contact-drawing.svg" min-height="30vh" contain eager)
        div.contact-info
          v-row(no-gutters justify="end")
            v-col(cols="auto")
              v-row.mb-3(v-for="(info, i) of infos" :key="i" no-gutters)
                v-col(cols="auto")
                  v-img(:src="info.icon" width="16" contain eager)
                v-col.ml-2.mt-1(cols="auto")
                  div.beyond-text(v-html="info.text")

</template>

<script>
export default {
  name: "ContactBanner",
  data() {
    return {
      infos: [
        {
          icon: require("../../assets/icons/contact-location.svg"),
          text: "Rua General Polidoro, 714 - Sala 103/105<br>Várzea, Recife/PE"
        },
        {
          icon: require("../../assets/icons/contact-phone.svg"),
          text: "+55 81 3204-7240"
        }
      ]
    }
  }
}
</script>

<style lang="sass" scoped>
.banner-col
  position: relative

.banner-col
  @media(min-width: 960px)
    margin: 0 10px

  @media(min-width: 1264px)
    margin: 0 50px

.contact-info
  position: absolute
  right: 12px
  margin-top: 128px

  .beyond-text
    line-height: 1
    font-size: 14px
    font-weight: normal
</style>
