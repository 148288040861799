<template lang="pug">
  v-container#contact.pa-0(fluid fill-height)
    div#contact-social(v-if="$vuetify.breakpoint.smAndUp")
      v-btn(v-for="(social, i) of socials" :key="i" :aria-label="social.label" icon @click="openUrl(social.url)")
        v-img(:src="social.icon" :alt="social.label" height="18" contain eager)
    v-row(no-gutters)
      v-col#contact-form
        ContactForm
      v-col#contact-banner(v-if="$vuetify.breakpoint.mdAndUp")
        ContactBanner
</template>

<script>
import ContactForm from "../components/contact/ContactForm"
import ContactBanner from "../components/contact/ContactBanner"

export default {
  metaInfo: {
    title: "Contato"
  },
  components: {
    ContactForm,
    ContactBanner
  },

  data: () => ({
    socials: [
      {
        icon: require("../assets/icons/contact-linkedin.svg"),
        label: "LinkedIn Page",
        url: "https://www.linkedin.com/company/beyond-cloud/"
      },
      {
        icon: require("../assets/icons/contact-instagram.svg"),
        label: "Instagram Page",
        url: "https://www.instagram.com/co_beyond/"
      },
      {
        icon: require("../assets/icons/contact-facebook.svg"),
        label: "Facebook Page",
        url: ""
      }
    ]
  }),

  mounted() {},

  methods: {
    openUrl(url) {
      window.open(url, "_blank")
    }
  }
}
</script>

<style lang="sass" scoped>
#contact
  >.row
    height: 100%

  &-banner
    background: var(--v-beyondYellow-base)

  &-social
    position: absolute
    left: 18px
    display: flex
    flex-direction: column
    align-items: center
    justify-items: center

    @media(min-width: 1264px)
      left: 50px

    .v-btn:nth-child(2n)
      margin: 15px 0
</style>
